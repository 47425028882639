import { AxiosError } from 'axios';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import {
  refundRegister,
  refundRegisterPorte,
  temporaryStorageSave,
} from '@/api/refund';
import {
  IRefundPayload,
  IRefundResponse,
  ISubmitData,
  StoragePayload,
} from '@/types/refund';
import AmountDetail from './AmountDetail';
import SupplyDetail from './SupplyDetail';
import TouristInput from './TouristInput';
import { getRefundAmount } from '@/util/getRefundAmount';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { refundIndexState } from '@/store/modules/user';
import { reset } from '@/store/modules/refund';
import LeafletModal from '@/components/refund/LeafletModal';
import getCurrentTime, { getNumberFullTime } from '@/util/getCurrentTime';
import {
  attachedHyphensDate,
  convertToKoreanUnit,
  removeComma,
} from '@/util/format';
import { updateModal } from '@/store/modules/modal';
import confirmIcon from '@/assets/common/confirm.png';
import { updateLoading } from '@/store/loading.store';
import PorteRefundModal from './porte/PorteRefund.modal';
import PorteOverPriceModal from './porte/PorteOverPrice.modal';

const orderInfoList = {
  productType: '',
  price: '',
  supplyPrice: '',
  vatPrice: '',
  isCheck: false,
};

interface IProps {
  setIsRefund: Dispatch<SetStateAction<boolean>>;
  isExpired: boolean;
}

interface ISupplyItem {
  price: string;
  productType: string | number;
  supplyPrice: string;
  vatPrice: string;
  isCheck?: boolean;
}

function RefundContent({ setIsRefund, isExpired }: IProps) {
  const [isPayment, setIsPayment] = useState(true);
  const [leafletModalInfo, setLeafletModalInfo] = useState({
    active: false,
    isOver550: false,
    isKioskError: false,
  });
  const [errorMessage, setErrorMessage] = useState({
    isSubmit: false,
    message: '',
  });
  const [isFetchedStorageData, setIsFetchedStorageData] = useState(false);
  const { touristIndex, isAutoPayment, isKioskStore, hospitalId } =
    useAppSelector((state) => state.user);
  const { isDirectPayment, isScanned, temporaryStorage } = useAppSelector(
    (state) => state.refund,
  );
  const { isPassportCheck } = useAppSelector((state) => state.validate);
  const dispatch = useAppDispatch();
  // 포르테 한국인
  const [isPorteKor, setIsPorteKor] = useState(false);
  const [isPorteLeaflet, setIsPorteLeaflet] = useState(false);

  const onCloseModal = () => {
    dispatch(updateModal({ isActive: false, data: null, type: null }));
  };

  // 환급하기 할때 서버로 보내는 body 값
  const methods = useForm({
    defaultValues: {
      touristName: '',
      departureDate: '',
      paymentCard: '0',
      paymentCash: '0',
      supply: [orderInfoList, orderInfoList, orderInfoList],
      supplyDate: attachedHyphensDate(getNumberFullTime(new Date())),
      name: '',
      nationality: '',
      passportNumber: '',
      saleDate: getCurrentTime(),
      birthYear: '',
      birthMonth: { label: '', value: '' },
      birthDay: '',
    },
  });
  const { handleSubmit, setValue, setFocus, getValues } = methods;

  // 임시저장 값 불러오기
  useEffect(() => {
    if (temporaryStorage.supplyDate) {
      setValue('supplyDate', temporaryStorage.supplyDate);
      setValue('touristName', temporaryStorage.touristName);
      setValue('passportNumber', temporaryStorage.passportNumber);
      setValue('nationality', temporaryStorage.nationality);
      setIsFetchedStorageData(true);
    }
  }, [temporaryStorage]);

  const queryClient = useQueryClient();

  const { mutate: storageMutation } = useMutation<
    number,
    AxiosError,
    StoragePayload
  >((payload) => temporaryStorageSave(payload), {
    retry: false,
    onSuccess: () => {
      dispatch(
        updateModal({
          isActive: true,
          type: 'CUSTOM',
          data: {
            icon: confirmIcon,
            title: '임시 저장 성공',
            content: '입력하신 정보를 임시저장했습니다.',
            btnText: '확인',
            subBtnText: '',
            onClose: onCloseModal,
          },
        }),
      );
      queryClient.invalidateQueries({ queryKey: 'allStorage' });
    },
    onError: (error) => {
      console.log('에러', error);
    },
  });

  const { mutate: refundMutation, isLoading } = useMutation<
    IRefundResponse,
    AxiosError,
    IRefundPayload
  >((payload) => refundRegister(payload), {
    retry: false,
    onSuccess: (data) => {
      dispatch(refundIndexState(data));
      dispatch(reset());
      setIsRefund(true);
      setIsPayment(false);
      dispatch(updateLoading({ isLoading: false }));
    },
    onError: (error) => {
      //@ts-ignore
      const { code, message } = error.response?.data;
      switch (code) {
        case 'H0006':
          alert(
            '유치기관 만료일을 초과했습니다. 만료일 갱신 후 다시 시도해 주세요.',
          );
          break;
        default:
          if (message) {
            return alert(message);
          }
          return alert('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      }
      dispatch(updateLoading({ isLoading: false }));
    },
  });

  const onSubmit: SubmitHandler<ISubmitData> = (data) => {
    const { paymentCard, paymentCash, supply, supplyDate, touristName } = data;

    let totalPrice = 0;
    let totalRefund = 0;
    const newPaymentCard = paymentCard.replace(/(,)/g, '');
    const newPaymentCash = paymentCash.replace(/(,)/g, '');
    const newSupply = supply.filter(
      (item) => item.price && String(item.productType),
    );

    newSupply.forEach((item: ISupplyItem) => {
      item.productType = +item.productType;
      delete item.isCheck;
    });

    data.supply.forEach((item) => {
      item.price = item.price.replace(/(,)/g, '');
      totalPrice += Number(item.price);
    });
    totalRefund = getRefundAmount(totalPrice);

    if (totalPrice < 15000) {
      setErrorMessage((prev) => ({
        ...prev,
        isSubmit: true,
        message: '최소 금액 15,000원 이상 입니다.',
      }));
      return;
    }

    // @ts-ignore
    if (
      (hospitalId === 138 || hospitalId === 1) &&
      totalPrice > 5500000 &&
      !isPorteKor
    ) {
      return onSubmitPorte(data);
    }

    const payload: IRefundPayload = {
      touristName: touristName.toUpperCase(),
      supplyDate,
      totalRefund: String(totalRefund),
      totalPrice: String(totalPrice),
      paymentCard: String(newPaymentCard),
      paymentCash: String(newPaymentCash),
      touristId: String(touristIndex),
      orderInfoList: newSupply,
      paymentType: !isAutoPayment || isDirectPayment ? 'DIRECT' : 'ORIGINAL',
      isScanned,
      tempInformationId: temporaryStorage.tempInformationId,
    };

    dispatch(
      updateModal({
        isActive: true,
        type: 'PRICE_CHECK',
        data: {
          icon: '',
          title: '결제금액을 확인해주세요. ',
          content:
            convertToKoreanUnit(
              isAutoPayment && !isDirectPayment
                ? totalPrice - getRefundAmount(totalPrice)
                : totalPrice,
            ) + '원',
          btnText: '확인',
          subBtnText: '다시 입력',
          onClose: onCloseModal,
          btnCallback: () => {
            dispatch(updateLoading({ isLoading: true }));
            refundMutation(payload);
          },
        },
      }),
    );
  };

  // 포르테 의원
  // 포르테 환급하기
  const { mutate: refundPorte } = useMutation<IRefundResponse, AxiosError, any>(
    (payload) => refundRegisterPorte(payload),
    {
      retry: false,
      onSuccess: (data) => {
        dispatch(refundIndexState(data));
        dispatch(reset());
        setIsRefund(true);
        setIsPayment(false);
        dispatch(updateLoading({ isLoading: false }));
      },
      onError: (error) => {
        //@ts-ignore
        const { code, message } = error.response?.data;
        switch (code) {
          case 'H0006':
            alert(
              '유치기관 만료일을 초과했습니다. 만료일 갱신 후 다시 시도해 주세요.',
            );
            break;
          default:
            if (message) {
              return alert(message);
            }
            return alert('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        }
        dispatch(updateLoading({ isLoading: false }));
      },
    },
  );

  const onSubmitPorte: SubmitHandler<ISubmitData> = (data) => {
    const { supplyDate, touristName } = data;
    // @ts-ignore
    if ((hospitalId === 138 || hospitalId === 1) && data.porteRefund) {
      let totalPrice = 0;
      let totalRefund = 0;
      let realRefund = 0;

      // @ts-ignore
      const refundDetail = data.porteRefund.map((refund, index) => {
        // @ts-ignore
        const isLast = data.porteRefund.length === index + 1;
        const portePrice = refund.price.replace(/(,)/g, '');
        const porteRefund = refund.refund.replace(/(,)/g, '');
        const differenceRefund =
          removeComma(refund.price) -
          (removeComma(refund.paymentCard) +
            removeComma(refund.paymentCash) +
            getRefundAmount(+portePrice));
        totalPrice += Number(portePrice);
        totalRefund += Number(porteRefund);
        realRefund += getRefundAmount(+portePrice);

        return {
          paymentCard: isLast
            ? removeComma(refund.paymentCard) + differenceRefund
            : removeComma(refund.paymentCard),
          paymentCash: removeComma(refund.paymentCash),
          price: removeComma(refund.price),
          refund: getRefundAmount(+portePrice),
          orderInfoList: [
            {
              // @ts-ignore
              productType: data.productType,
              price: +portePrice,
            },
          ],
        };
      });

      const portePayload = {
        common: {
          supplyDate,
          totalRefund,
          totalPrice,
          refundDifference: totalRefund - realRefund,
          touristId: String(touristIndex),
          touristName,
          paymentType: 'ORIGINAL',
          isScanned,
        },
        details: refundDetail,
      };

      // 포르테 의원 15000원 이하 처리
      if (hospitalId === 138) {
        const under15000 = portePayload.details.filter(
          ({ price }: { price: number }) => price < 15000,
        );
        if (under15000.length > 0) {
          setErrorMessage((prev) => ({
            ...prev,
            isSubmit: true,
            message: '각 시술 최소 금액 15,000원 이상 입니다.',
          }));
          return;
        }
      }

      return dispatch(
        updateModal({
          isActive: true,
          type: 'PRICE_CHECK',
          data: {
            icon: '',
            title: '결제금액을 확인해주세요. ',
            content:
              convertToKoreanUnit(
                isAutoPayment && !isDirectPayment
                  ? totalPrice - totalRefund
                  : totalPrice - totalRefund,
              ) + '원',
            btnText: '확인',
            subBtnText: '다시 입력',
            onClose: onCloseModal,
            btnCallback: () => {
              dispatch(updateLoading({ isLoading: true }));
              refundPorte(portePayload);
            },
          },
        }),
      );
    }
  };

  const onClickStorage = () => {
    const {
      touristName,
      nationality,
      passportNumber,
      supplyDate,
      supply,
      birthYear,
      birthMonth,
      birthDay,
      paymentCard,
      paymentCash,
    } = methods.getValues();

    const payload = {
      hospitalId,
      supplyDate: supplyDate,
      nation: nationality,
      name: touristName.toUpperCase(),
      passportNumber,
      paymentCash,
      paymentCard,
      orderInfoList: supply,
      year: birthYear,
      month: birthMonth.value,
      day: birthDay,
      tempInformationId: temporaryStorage.tempInformationId,
    };
    storageMutation(payload);
  };

  const focusTouristNameWhenClickAnywhere = (e: any) => {
    const { id, tagName } = e.target;
    if (
      id !== 'no_focus' &&
      !['INPUT', 'BUTTON', 'LI', 'IMG', 'UL'].includes(tagName)
    ) {
      setFocus('touristName');
    }
  };

  // 포르테 의원
  const [isOpen, setIsOpen] = useState(false);
  const [isOver550, setIsOver550] = useState(false);

  return (
    <FormProvider {...methods}>
      <Container onClick={focusTouristNameWhenClickAnywhere}>
        <TouristInput
          setIsPorteLeaflet={setIsPorteLeaflet}
          setIsPorteKor={setIsPorteKor}
          setLeafletModalInfo={setLeafletModalInfo}
          setIsRefund={setIsRefund}
          setIsPayment={setIsPayment}
          isExpired={isExpired}
          setIsOpen={setIsOpen}
        />
        <SupplyDetail setIsOpen={setIsOpen} isPorteKor={isPorteKor} />
        <AmountDetail
          isPorteKor={isPorteKor}
          isPayment={isPayment}
          setLeafletModalInfo={setLeafletModalInfo}
          errorMessage={errorMessage}
          onClickSaveBtn={onClickStorage}
          onClickRefundBtn={handleSubmit(onSubmit)}
          isPassportCheck={isPassportCheck}
          isFetchedStorageData={isFetchedStorageData}
          isLoading={isLoading}
          supplyList={getValues('supply')}
        />
      </Container>

      {/* 포르테 의원이 아닌 경우 */}
      {hospitalId !== 138 &&
        hospitalId !== 1 &&
        (isKioskStore || isAutoPayment) &&
        leafletModalInfo.active && (
          <LeafletModal
            isKioskStore={isKioskStore}
            leafletModalInfo={leafletModalInfo}
            setLeafletModalInfo={setLeafletModalInfo}
          />
        )}

      {/* 포르테 의원 + 한국인 경우 */}
      {(hospitalId === 138 || hospitalId === 1) &&
        isPorteKor &&
        isPorteLeaflet && (
          <LeafletModal
            isKioskStore={isKioskStore}
            leafletModalInfo={leafletModalInfo}
            setLeafletModalInfo={setLeafletModalInfo}
            setIsPorteLeaflet={setIsPorteLeaflet}
          />
        )}
      {/* 포르테 의원인 경우 */}
      {(hospitalId === 138 || hospitalId === 1) &&
        isPassportCheck &&
        isOpen && (
          <PorteRefundModal
            onClose={(isOver: boolean) => {
              setIsOpen(false);
              setIsOver550(isOver);
            }}
          />
        )}
      {/* 포르테 의원인 경우 */}
      {(hospitalId === 138 || hospitalId === 1) &&
        isPassportCheck &&
        isOver550 && (
          <PorteOverPriceModal
            onClose={() => {
              setIsOver550(false);
            }}
          />
        )}
    </FormProvider>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 36px;
  flex: 1;
  padding: 22px 40px 85px 40px;
  @media ${(props) => props.theme.laptop} {
    gap: 24px;
  }
`;

export default RefundContent;
